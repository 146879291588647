import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";


/**
 * Botão outiline com gradient.
 * @param {string} type - Tipo ensino, comercial ou institucional.
 * @param {string} to - Link para rediracionamento.
 * @param {string} text - Texto botão.
 * @param {boolean} rounded - Bordas aredondadas (Default false).
 */

export default function Button({ type, to, text, rounded=false}) {
	Button.propTypes = {
		type: PropTypes.string,
		to: PropTypes.string,
        text: PropTypes.string,
        rounded:PropTypes.bool,
	};

	return (
		<Link to={to} className="text-decoration-none" target={(to.indexOf("http") !== -1 || to.indexOf("pdf") !== -1  ) && "_blank"}>
			<button className={rounded ? "rounded-pill p-2 " + type : "p-2 " + type}>
				<span dangerouslySetInnerHTML={{ __html: text }} />
			</button>
		</Link>
	);
}
