import React from "react";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
} from "mdbreact";

import PropTypes from "prop-types";

/**
 * 	Card de valores.
 *  @param {string} bgColor: "string", style para backgound.
 *  @param {string} amount: "string", quantidade de parcelas.
 *  @param {string} value: "string", valor separado por virgula.
 */
export default function CardValores({ bgColor, amount, value, type = 1 }) {
    CardValores.propTypes = {
		bgColor: PropTypes.string,
		amount: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.number,
	};

    return (
        <MDBCol col={12} md={4} className="pb-2">
            <MDBContainer className={bgColor + " py-4 text-white text-center"} >
                <MDBRow>
                    <MDBCol>
                        {type === 2 ?
                            <p className="m-0" style={{ fontSize: "1.4em"}}>{ amount }</p>
                        :
                            <span className="display-4 font-weight-bold">{ amount }x</span>
                        }{' '}
                        <span style={{ fontSize: "1.4em"}}>R${' '}{ value }</span>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </MDBCol>
    )
}