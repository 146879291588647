import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import Button from "../../components/Button/Button";
import CardValores from "../../components/CardValores/CardValores";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgMenina from "../../assets/images/etapas-de-aprendizagem/educacao-infantil/educacao-infantil.png";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function EnsinoInfantil() {
	return (
		<Layout>
			<SEO title="Educação Infantil" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgterciary-gradient">educação infantil</h1>
						<p className="font-weight-bold pb-5">// a partir de 2 anos.</p>
						<h3 className="fgterciary-gradient">nosso propósito</h3>
						<p className="text-justify">
							Na Escola de Aplicação Feevale, as práticas pedagógicas são
							abordadas a partir de interações e brincadeiras. São experiências
							nas quais as crianças aprendem através das suas ações e do
							relacionamento com outras crianças e com os adultos. Para além do
							aprender, essa abordagem possibilita o desenvolvimento e a
							socialização, dando condições para que a criança participe
							ativamente de diferentes situações.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-5 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgMenina} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6}>
						<h3 className="fgterciary-gradient text-left">horários</h3>
						<ul style={{ listStyleType: "none" }}>
							<li>// manhã: das 7h20min às 11h50min.</li>
							<li>// tarde: das 13h20min às 17h50min.</li>
						</ul>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgterciary-gradient">
							conheça as atividades de contraturno
						</h3>
						<Button
							type="ensino"
							to="/contraturno/atividades-extracurriculares"
							text="Atividades extracurriculares"
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgterciary-gradient pt-3 pb-2">valores*</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="12"
						value="1.646,59"
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="11"
						value="1.796,28"
					/>
					<CardValores
						bgColor="bgterciary-gradient"
						amount="10"
						value="1.975,90"
					/>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
